import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Slider from '../components/slider';
import SEO from '../components/seo';

const Chapter = ({ data }) => {
  const { content } = data.chapter;

  return (
    <Layout color="white">
      <SEO siteTitle="photo" />
      <Slider content={content} chapter />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    chapter: sanityChapter(slug: { current: { eq: $slug } }) {
      content {
        ... on SanityMainImage {
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          portrait
          spacing
        }
        ... on SanityImageGallery {
          images {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          spacing
        }
        ... on SanityMainText {
          _rawText
          spacing
        }
      }
    }
  }
`;

Chapter.propTypes = {
  data: PropTypes.object,
};

export default Chapter;

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery, navigate } from 'gatsby';
import { media } from '../utils/media-queries';
import { useWindowSize } from '../hooks/useWindowSize';
import RichText from './richtext';
import { useTransformScroll } from '../hooks/useTransformScroll';
import { useClientSide } from '../hooks/useClientSide';
import { useEventListener } from '../hooks/useEventListener';
import Figure from './figure';

const Slider = ({ content, slugLink, chapter }) => {
  const { slugs } = useStaticQuery(graphql`
    query Slugs {
      slugs: allSanityPhotosPage {
        nodes {
          photoProjects {
            hidden
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const filteredSlugs = [];
  const allSlugs = slugs.nodes[0].photoProjects;
  allSlugs.map(slug => !slug.hidden && filteredSlugs.push(slug.slug.current));
  const randomNum = Math.floor(Math.random() * filteredSlugs.length);
  const randomProject = filteredSlugs[randomNum];

  const sliderRef = useRef(null);

  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState();
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleDown = e => {
    setIsDown(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleLeave = () => {
    setIsDown(false);
  };

  const handleUp = () => {
    setIsDown(false);
  };

  const handleMove = e => {
    if (!isDown) return;
    const x = e.pageX - sliderRef.current.offsetLeft;
    const distance = (x - startX) * 2;
    sliderRef.current.scrollLeft = scrollLeft - distance;
  };

  const size = useWindowSize();
  const isClient = useClientSide();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(size.width < 768);
  }, [size.width]);

  const keyScroll = e => {
    if (sliderRef.current === null) return;

    const speed = 10;
    if (e.keyCode === 40 || e.keyCode === 39)
      sliderRef.current.scrollLeft += speed;
    if (e.keyCode === 38 || e.keyCode === 37)
      sliderRef.current.scrollLeft -= speed;
  };

  useEventListener('keydown', keyScroll, isClient && document);

  const variableSpacing = item =>
    item.spacing && `var(--spacing-${item.spacing.toUpperCase()})`;

  return (
    <StyledSlider
      ref={sliderRef}
      onMouseDown={handleDown}
      onMouseLeave={handleLeave}
      onMouseUp={handleUp}
      onMouseMove={handleMove}
      onWheel={!isMobile && useTransformScroll}
      onScroll={() => {
        setScrollLeft(sliderRef.current.scrollLeft);
      }}
    >
      {content.map(
        (item, i) =>
          (item.__typename === 'SanityMainImage' && (
            <Figure isMobile={isMobile} item={item} i={i} />
          )) ||
          (item.__typename === 'SanityImageGallery' &&
            item.images.map((image, index) => (
              <Figure
                isMobile={isMobile}
                item={item}
                i={index}
                image={image}
                gallery
              />
            ))) ||
          (item.__typename === 'SanityMainText' && (
            <div
              key={`text-${i}`}
              className="text"
              style={
                isMobile
                  ? { marginBottom: variableSpacing(item) }
                  : { marginRight: variableSpacing(item) }
              }
            >
              <RichText blocks={item._rawText} />
            </div>
          ))
      )}
      <div className="woanders">
        <p>
          {filteredSlugs.length > 1 && (
            <Link to={`/photo/${randomProject}`}>woandershin →</Link>
          )}
        </p>
      </div>
      <div className="arrows">
        <button
          type="button"
          className="back"
          style={{
            opacity: scrollLeft === 0 ? 0.3 : 1,
            pointerEvents: scrollLeft === 0 ? 'none' : 'all',
          }}
          onClick={() => {
            sliderRef.current.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            setScrollLeft(0);
          }}
        >
          <svg height="315" viewBox="0 0 235 315" width="235">
            <g
              fill="#ffc5bf"
              fillRule="evenodd"
              transform="matrix(-1 0 0 1 234.5 .5)"
            >
              <path
                d="m175.5 98.5 157 117h-314z"
                transform="matrix(0 1 -1 0 332.5 -18.5)"
              />
              <path
                d="m58.5 98.5 157 117h-314z"
                transform="matrix(0 1 -1 0 215.5 98.5)"
              />
            </g>
          </svg>
        </button>
        <button
          type="button"
          className="forward"
          style={{
            opacity:
              !slugLink &&
              scrollLeft ===
                sliderRef.current?.scrollWidth - sliderRef.current?.offsetWidth
                ? 0.3
                : 1,
            pointerEvents:
              !slugLink &&
              scrollLeft ===
                sliderRef.current?.scrollWidth - sliderRef.current?.offsetWidth
                ? 'none'
                : 'all',
          }}
          onClick={() => {
            if (
              scrollLeft ===
                sliderRef.current?.scrollWidth -
                  sliderRef.current?.offsetWidth &&
              slugLink
            ) {
              navigate(slugLink);
            } else {
              sliderRef.current.scrollTo({
                top: 0,
                left: sliderRef.current.scrollWidth,
                behavior: 'smooth',
              });
            }
          }}
        >
          <svg height="315" viewBox="0 0 235 315" width="235">
            <g fill="#ffc5bf" fillRule="evenodd" transform="translate(.5 .5)">
              <path
                d="m175.5 98.5 157 117h-314z"
                transform="matrix(0 1 -1 0 332.5 -18.5)"
              />
              <path
                d="m58.5 98.5 157 117h-314z"
                transform="matrix(0 1 -1 0 215.5 98.5)"
              />
            </g>
          </svg>
        </button>
      </div>
    </StyledSlider>
  );
};

const StyledSlider = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .img-container {
    position: relative;
    .credit {
      position: absolute;
      background: rgba(255, 255, 255, 0.8);
      width: 100%;
      font-size: var(--fontsize-0);
      line-height: 1.2em;
      padding: 0.25rem 0.5rem 0.25rem 0.5rem;
      z-index: 1;
      bottom: 0;
      left: 0;
    }
  }

  .text,
  .img,
  .portrait-img,
  .square-img {
    pointer-events: none;
    user-select: none;
  }

  .text {
    padding: 0 var(--spacing-XXS);
    white-space: pre-wrap;
  }

  .woanders {
    display: flex;
    justify-content: center;
    p {
      a {
        padding: var(--spacing-XS);
      }
    }
  }

  .arrows {
    display: none;
  }

  @media ${media.M} {
    width: 100%;
    height: 80vh;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    cursor: ew-resize;
    padding-bottom: 10vh;

    .img-container {
      position: static;
      .credit {
        width: auto;
        max-width: 30vw;
        top: 0;
        left: 0;
        bottom: auto;
      }
    }

    .arrows {
      position: absolute;
      bottom: 0;
      right: var(--spacing-M);
      display: flex;

      .back,
      .forward {
        padding: var(--spacing-XXS);
        width: 60px;

        svg {
          height: 100%;
          width: 100%;
        }
      }

      .back {
        margin-right: var(--spacing-XS);
      }

      .forward {
        margin-left: var(--spacing-XS);
        opacity: 30%;
      }
    }

    p {
      width: 40vw;
    }

    .img-container {
      width: 105vh;
      height: 70vh;
    }

    .img {
      width: 105vh;
      height: 70vh;
    }

    .portrait-img {
      width: 50vh;
      height: 70vh;
    }

    .square-img {
      width: 70vh;
      height: 70vh;
    }

    .text {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .woanders {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: nowrap;
      min-width: 400px;

      p {
        width: auto;
        height: auto;
        display: inline-block;
        transform: rotate(270deg);
      }
    }
  }
`;

Slider.propTypes = {
  content: PropTypes.array,
};

export default Slider;

/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';

const Figure = ({ isMobile, item, i, gallery = false, image }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isTap, setIsTap] = useState(false);
  const [xPos, setXPos] = useState();
  const [yPos, setYPos] = useState();
  const timerRef = useRef(null);

  const imgClass = el => {
    if (el.portrait) return 'portrait-img';
    if (el.square) return 'square-img';
    return 'img';
  };

  const variableSpacing = el =>
    el.spacing && `var(--spacing-${item.spacing.toUpperCase()})`;

  return (
    <figure
      className="img-container"
      style={
        isMobile
          ? { marginBottom: variableSpacing(item) }
          : { marginRight: variableSpacing(item) }
      }
      key={gallery ? `gallery-${i}` : `image-${i}`}
      onMouseMove={e => {
        e.persist();

        setIsMouseOver(true);
        setXPos(e.pageX);
        setYPos(e.pageY);
      }}
      onMouseOut={() => {
        clearTimeout(timerRef.current);
        setIsMouseOver(false);
      }}
      onTouchMove={e => {
        e.persist();
        setIsTap(false);
      }}
      onTouchStart={() => {
        setIsTap(true);
      }}
      onTouchEnd={() => {
        setTimeout(() => {
          setIsTap(false);
        }, 12000);
      }}
    >
      <Img
        className={gallery ? 'img' : imgClass(item)}
        fluid={gallery ? image.asset.fluid : item.image.asset.fluid}
        alt={item.alt}
      />
      {item.credits && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={
            isMobile
              ? { opacity: isTap ? 1 : 0 }
              : { opacity: isMouseOver ? 1 : 0 }
          }
          transition={{
            duration: isMobile ? 0.7 : 0.2,
            delay: isMobile ? 0.3 : 0,
          }}
          className="credit"
          style={
            isMobile
              ? {
                  bottom: 0,
                  left: 0,
                }
              : {
                  top: `${yPos + 10}px`,
                  left: `${xPos + 10}px`,
                }
          }
        >
          {item.credits}
        </motion.div>
      )}
    </figure>
  );
};

Figure.propTypes = {
  isMobile: PropTypes.bool,
  gallery: PropTypes.bool,
  i: PropTypes.number,
  item: PropTypes.object,
  image: PropTypes.object,
};

export default Figure;
